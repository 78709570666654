var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "w": "100%",
      "flex-dir": "column",
      "justify-content": "center",
      "align-items": "center",
      "bg": "#F2F2F2",
      "border-radius": "12px",
      "my": "10px"
    }
  }, [_c('c-image', {
    attrs: {
      "w": "142px",
      "h": "148px",
      "m": "24px",
      "src": require('@/assets/img-meal-plan-empty-illustration.svg')
    }
  }), _c('c-text', {
    attrs: {
      "mt": "30px",
      "font-size": "16px",
      "line-height": "150%",
      "text-align": "center",
      "color": "#555555",
      "my": "20px"
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }