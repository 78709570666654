var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('HeaderComp'), _c('c-box', {
    attrs: {
      "max-w": "1200",
      "mx": "auto",
      "mt": "30px",
      "px": ['20px', '0']
    }
  }, [_c('Breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  }), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "mt": "20px"
    }
  }), _c('c-flex', {
    attrs: {
      "my": "30px"
    }
  }, [_c('Navigation'), _c('c-box', {
    attrs: {
      "ml": ['0', '20px'],
      "w": "full"
    }
  }, [_vm.hasProgram ? _c('router-view') : _c('c-box', [_c('EmptyState', {
    attrs: {
      "title": "Anda tidak memiliki program aktif"
    }
  })], 1)], 1)], 1)], 1), _c('FooterComp')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }