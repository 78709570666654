<template>
  <c-flex
    as="nav"
    :position="['fixed', 'static']"
    :bottom="[0]"
    :h="['60px', 'auto']"
    :w="['100vw', 'auto']"
    background="white"
    align-items="center"
    justify-content="space-around"
    border-right="1px solid #f2f2f2"
    pr="18px"
    pb="60px"
    left="0"
    :box-shadow="['4px -4px 50px 5px rgba(0, 0, 0, 0.15)', 'none']"
    :d="['flex', 'block']"
    :z-index="[999, 0]"
    :pt="[null, '33px']"
  >
    <c-link
      v-for="menu in menus"
      :key="menu.name"
      as="router-link"
      :to="menu.route"
      class="menu-item"
      exact-active-class="active"
      :_focus="{
        outline: 'none',
      }"
    >
      <c-flex
        v-chakra="{
          '.active &': {
            bg: [null, '#f5f5f5'],
          },
        }"
        :flex-direction="['column', 'row']"
        :justify-content="['center', 'flex-start']"
        align-items="center"
        :h="[null, '66px']"
        :w="[null, '257px']"
        :border-radius="[null, '12px']"
      >
        <c-box
          v-chakra="{
            '.active &': {
              bg: [null, 'primary.400'],
            },
          }"
          w="7px"
          h="100%"
          border-top-left-radius="15px"
          border-bottom-left-radius="15px"
        />
        <c-image
          v-if="menu.icon"
          class="icon"
          :alt="menu.name"
          :src="menu.icon"
          w="24px"
          h="24px"
          :ml="[null, '16px']"
        />
        <c-text
          v-chakra="{
            '&.active': {
              color: '#008C81',
            },
          }"
          :class="{ active: menu.isActive }"
          :font-size="['12px', '14px']"
          :line-height="['21px']"
          :font-weight="['500']"
          :mx="[null, '20px']"
          :mb="['-60px', '0']"
          color="#555555"
          v-html="menu.name"
        />
      </c-flex>
    </c-link>
  </c-flex>
</template>

<script>
export default {
  name: 'NavigationProgressTracker',
  computed: {
    menus() {
      const self = this
      return [
        {
          name: 'Sedang berjalan',
          route: {
            name: 'client.progress-tracker.current',
          },
          get isActive() {
            return self.$route.name === this.route.name
          },
        },
        {
          name: 'Histori',
          route: {
            name: 'client.progress-tracker.history',
          },
          get isActive() {
            return self.$route.name === this.route.name
          },
        },
      ]
    },
  },
}
</script>
