<template>
  <c-box>
    <HeaderComp />

    <c-box
      max-w="1200"
      mx="auto"
      mt="30px"
      :px="['20px', '0']"
    >
      <Breadcrumb :items="breadcrumbItems" />
      <hr
        v-chakra
        mt="20px"
      >

      <c-flex my="30px">
        <Navigation />
        <c-box
          :ml="['0', '20px']"
          w="full"
        >
          <router-view v-if="hasProgram" />
          <c-box v-else>
            <EmptyState title="Anda tidak memiliki program aktif" />
          </c-box>
        </c-box>
      </c-flex>
    </c-box>
    <FooterComp />
  </c-box>
</template>

<script>
import HeaderComp from '@/components/header/index.vue'
import Breadcrumb from '@/components/breadcrumb'
import Navigation from './navigation.vue'
import FooterComp from '@/components/layouts/footer/index.vue'
import EmptyState from '@/views/meal-plan/ahli-gizi/empty-state.vue'

export default {
  name: 'ProgressTrackerPage',
  components: {
    FooterComp,
    Breadcrumb,
    HeaderComp,
    Navigation,
    EmptyState,
  },
  data() {
    return {
      hasProgram: false,
    }
  },
  computed: {
    programId() {
      return this.$route.query.programId
    },
    progressId() {
      return this.$route.query.progressId
    },
    breadcrumbItems() {
      return [
        { label: 'Beranda', isCurrent: false, href: '/' },
        { label: 'Progress & Tracker', isCurrent: true },
      ]
    },
  },
  watch: {
    programId: {
      immediate: true,
      async handler() {
        // this.axios.get(`/programs/${id}/progress-tracker`)
        // .then((r) => r.data)
      },
    },
    progressId: {
      immediate: true,
      async handler() {
        // this.axios.get(`/v1/clients/progress-tracker/${id}`)
        // .then((r) => r.data)
      },
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('clientProgressTracker/getProgressData', { id: this.programId })
      this.hasProgram = true
    } catch (err) {
      let msg = err.response.data?.message
      if (msg.includes('tidak memiliki program aktif')) {
        this.hasProgram = false
        return
      }
    }
  },
}
</script>
